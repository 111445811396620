import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<unknown>,
  ) => <Slide direction="right" ref={ref} {...props} />,
);

type MonitoringDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function MonitoringDialog({ open, onClose }: MonitoringDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { position: 'fixed', left: 0, top: 0, bottom: 0, m: 0, backgroundColor: '#ECF9F3' } }}
      TransitionComponent={Transition}
    >
      <DialogActions>
        <Button onClick={onClose} sx={{ color: 'black', fontSize: 30, fontWeight: 'normal' }}>
          X
        </Button>
      </DialogActions>
      <DialogTitle>Hinweise zur Datenerhebung</DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: 2 }}>
          Mit dieser Erhebung werden die Verkehrsmittelnutzung und die Entfernung zwischen Wohn- und Arbeits- bzw.
          Veranstaltungsort erfasst. Die Daten werden für die Ermittlung der Verkehrsmittelnutzung und eine grobe
          CO2-Bilanzierung verwendet.
        </Typography>
        <Typography>
          Die Erfassung ist eingebunden in das Beratungsprogramm Besser zur Arbeit, ein Angebot der ivm GmbH Region
          Frankfurt RheinMain. Die Daten stehen für die weitere Nutzung Ihrem Arbeitgeber bzw. dem Veranstalter sowie
          der ivm GmbH zur Verfügung. Sie müssen keine Angaben zu Ihrer Person machen.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
