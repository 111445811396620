import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useScrollSpy from 'react-use-scrollspy';

import { DeleteDialog } from '@/components/form-elements/DeleteDialog';
import { LastSaved } from '@/components/form-elements/LastSaved';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { useQueryOrganizationDefaultFilters } from '@/hooks/queries/useQueryOrganization';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useAutoSave } from '@/hooks/useAutoSave';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { useSetValidationErrors } from '@/hooks/useSetValidationErrors';
import { AdminLevelForm, FormDataAdminLevelForm } from '@/location/AdminLevelForm';
import { CoreDataForm, FormDataLocationCoreData } from '@/location/CoreDataForm';
import { NetworksForm, FormDataNetworksForm } from '@/location/NetworksForm';
import { DataTransformer } from '@/services/DataTransformer';
import { QueryKeys } from '@/services/QueryKeys';
import {
  Action,
  Location,
  LocationsService,
  Network,
  NetworkCategory,
  NetworksService,
  UpdateLocationDto,
} from '@/services/api';

type FormData = FormDataLocationCoreData & FormDataAdminLevelForm & FormDataNetworksForm;

export function LocationCoreDataPage() {
  const { organizationId, locationId } = useParams();
  const navigate = useNavigate();
  const ability = useAppAbility();

  const sectionRefs = [useRef<HTMLElement>(null), useRef<HTMLElement>(null), useRef<HTMLElement>(null)];
  const activeItem = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -32,
    activeSectionDefault: 0,
  });

  const methods = useForm<FormData>();
  const { setValidationErrors } = useSetValidationErrors(methods.setError);
  const [lastSaved, setLastSaved] = useState<Dayjs>();

  const queryClient = useQueryClient();
  const { data: location } = useQuery({
    queryKey: QueryKeys.locations.id(locationId as string),
    queryFn: () => LocationsService.findOne({ id: locationId as string }),
    enabled: !!locationId,
  });
  const { data: networks } = useQuery({
    queryKey: QueryKeys.networks.all,
    queryFn: () => NetworksService.findAll(),
  });

  const matchedNetworks = useMemo(
    () =>
      (location?.networkIds || [])
        .map((id) => (networks || []).find((network) => network.id === id))
        .filter((network): network is Network => !!network),
    [location?.networkIds, networks],
  );

  useInitialFormValues<FormData>({
    entity: location &&
      networks && {
        ...location,
        consultingCaseLocationId: location.consultingCaseLocationId || '',
        networks: Object.values(NetworkCategory).reduce(
          (previousValue, networkCategory) => ({
            ...previousValue,
            [networkCategory]: matchedNetworks.find(({ category }) => category === networkCategory) || null,
          }),
          {} as Record<NetworkCategory, Network>,
        ),
      },
    useFormReturn: methods,
    fields: [
      // CoreData
      'name',
      'consultingCaseLocationId',
      'size',
      'address',
      // AdminLevel
      'adminLevelId',
      // Networks
      'networks',
    ],
  });

  const { mutate } = useMutation({
    mutationFn: (formData: UpdateLocationDto) =>
      LocationsService.update({ id: locationId as string, requestBody: formData }),
    onMutate: () => setLastSaved(undefined),

    onSuccess: async () => {
      setLastSaved(dayjs());

      await queryClient.invalidateQueries({
        queryKey: QueryKeys.organizations.idIncludes(organizationId as string, useQueryOrganizationDefaultFilters),
      });
      await queryClient.invalidateQueries({ queryKey: QueryKeys.locations.id(locationId as string) });
    },

    onError: setValidationErrors,
  });
  const { mutate: mutateRemove } = useMutation({
    mutationFn: () => LocationsService.remove({ id: locationId as string }),

    onSuccess: async () => {
      navigate(`/arbeitgeber/${organizationId}`);
      await queryClient.invalidateQueries({
        queryKey: QueryKeys.organizations.idIncludes(organizationId as string, { includeLocations: true }),
      });
    },
  });

  const handleSubmit = methods.handleSubmit(
    ({ networks: newNetworks = {}, ...formData }) => {
      const transformedFormData: UpdateLocationDto = DataTransformer.toApi(
        {
          ...formData,
          networkIds: Object.values(newNetworks)
            .filter((network) => !!network && !!network.id)
            .map(({ id }) => id),
        },
        (field) => ability.can(Action.UPDATE, location as Location, field),
      );

      mutate(transformedFormData);
    },
    () => setLastSaved(undefined),
  );
  useAutoSave(handleSubmit, methods.watch);

  const disabled = !location || ability.cannot(Action.UPDATE, location);

  return (
    <SidebarLayout
      type="scroll"
      activeItem={activeItem || 0}
      navigationItems={['Stammdaten', 'Räumliche Ebene', 'Kategorien']}
      navigationElements={sectionRefs}
      buttonLabel={disabled ? undefined : 'Speichern'}
      actionArea={<LastSaved lastSaved={lastSaved} />}
    >
      <FormProvider {...methods}>
        <form id="main-form" onSubmit={handleSubmit}>
          <CoreDataForm ref={sectionRefs[0]} location={location} isLoading={!location} />
          <AdminLevelForm ref={sectionRefs[1]} location={location} isLoading={!location} />
          <NetworksForm ref={sectionRefs[2]} location={location} isLoading={!location} />

          <DeleteDialog
            onDelete={mutateRemove}
            entityLabel="Standort"
            entityName={location?.name as string}
            can={ability.can(Action.DELETE, location as Location)}
          >
            Durch das Löschen des Standorts werden auch alle damit verknüpften Daten (z.B. Steckbrief) gelöscht.
          </DeleteDialog>
        </form>
      </FormProvider>
    </SidebarLayout>
  );
}
