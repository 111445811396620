import { Button, Chip, Typography, Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Can } from '@/components/casl/Can';
import { FormIndent } from '@/components/form-elements/FormIndent';
import { FormSection } from '@/components/form-elements/FormSection';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, LocationProfileStatus, LocationsService, Subject, UpdateLocationDto } from '@/services/api';

type LocationProfileStatusFormProps = { disabled?: boolean };

export function LocationProfileStatusForm({ disabled }: LocationProfileStatusFormProps) {
  const { locationId } = useParams();

  const { data: location } = useQuery({
    queryKey: QueryKeys.locations.id(locationId as string),
    queryFn: () => LocationsService.findOne({ id: locationId as string }),
    enabled: !!locationId,
  });

  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (formData: Pick<UpdateLocationDto, 'profileStatus'>) =>
      LocationsService.update({ id: location?.id as string, requestBody: formData }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QueryKeys.locationProfiles.id(location?.id as string) });
      await queryClient.invalidateQueries({ queryKey: QueryKeys.locations.id(location?.id as string) });
    },
  });

  return (
    <>
      <FormSection large title="Freigabe" />

      <FormSection title="Aktueller Status">
        <FormIndent>
          <Chip label={t(`LocationProfileStatus.${location?.profileStatus}`)} size="medium" />
        </FormIndent>
      </FormSection>

      {location?.profileStatus === LocationProfileStatus.EDITABLE && (
        <FormSection title="Standort-Steckbrief freigeben">
          <FormIndent>
            <Typography mb={2}>
              Haben Sie den Standort-Steckbrief vollständig ausgefüllt? Dann können Sie diesen zur Prüfung durch die ivm
              freigeben.
            </Typography>
          </FormIndent>

          <Button
            variant="contained"
            size="large"
            disabled={disabled}
            onClick={() => mutate({ profileStatus: LocationProfileStatus.SUBMITTED })}
          >
            Freigeben
          </Button>
        </FormSection>
      )}

      {location?.profileStatus === LocationProfileStatus.SUBMITTED && (
        <>
          <Can I={Action.MANAGE} not a={Subject.LOCATION}>
            <FormSection title="Standort-Steckbrief freigegeben">
              <FormIndent>
                <Typography mb={2}>
                  Der Standort-Steckbrief wurde zur Prüfung für die ivm freigegeben. Wenn Sie Änderungen vornehmen
                  wollen, können Sie die Freigabe widerrufen.
                </Typography>
              </FormIndent>

              <Can I={Action.UPDATE} not this={location} field="profileStatus" passThrough>
                {(can) => (
                  <Button
                    variant="contained"
                    size="large"
                    disabled={can}
                    onClick={() => mutate({ profileStatus: LocationProfileStatus.EDITABLE })}
                  >
                    Freigabe widerrufen
                  </Button>
                )}
              </Can>
            </FormSection>
          </Can>

          <Can I={Action.MANAGE} a={Subject.LOCATION}>
            <FormSection title="Standort-Steckbrief prüfen">
              <FormIndent>
                <Typography mb={2}>
                  Der Standort-Steckbrief wurde vom Arbeitgeber zur Prüfung freigegeben. Solange die Freigabe durch den
                  Arbeitgeber nicht widerrufen wird, kann dieser den Standort-Steckbrief nicht mehr bearbeiten.
                </Typography>
              </FormIndent>

              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={() => mutate({ profileStatus: LocationProfileStatus.EDITABLE })}
                  >
                    Ablehnen
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => mutate({ profileStatus: LocationProfileStatus.CONFIRMED })}
                  >
                    Bestätigen
                  </Button>
                </Grid>
              </Grid>
            </FormSection>
          </Can>
        </>
      )}

      {location?.profileStatus === LocationProfileStatus.CONFIRMED && (
        <FormSection title="Standort-Steckbrief freigegeben">
          <FormIndent>
            <Typography>Der Standort-Steckbrief wurde von der ivm erfolgreich geprüft.</Typography>
          </FormIndent>

          <Can I={Action.MANAGE} a={Subject.LOCATION}>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => mutate({ profileStatus: LocationProfileStatus.EDITABLE })}
              sx={{ marginTop: 2 }}
            >
              Prüfung widerrufen
            </Button>
          </Can>
        </FormSection>
      )}
    </>
  );
}
