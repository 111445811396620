import { Box, Button, css, Stack, styled, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { RadioButtonsCommute } from '@/components/form-elements/RadioButtonsCommute';
import { SliderDistance } from '@/components/form-elements/SliderDistance';
import { QueryKeys } from '@/services/QueryKeys';
import {
  ApiError,
  CommuteType,
  CreateMonitoringDto,
  Monitoring,
  MonitoringLinkType,
  MonitoringService,
} from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';

const CardTitle = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.primary.main};
    border-radius: ${theme.spacing(2)} ${theme.spacing(2)} 0 0;
    padding: ${theme.spacing(2)};
    color: ${theme.palette.primary.contrastText};
    justify-content: space-between;
    align-items: center;

    ${theme.breakpoints.up('md')} {
      display: flex;
      padding: ${theme.spacing(3)} ${theme.spacing(4)};
    }
  `,
) as typeof Box;

type FormData = Omit<CreateMonitoringDto, 'locationId'>;

export function MonitoringPage() {
  const [throttleError, setThrottleError] = useState(false);

  const monitoringThrottle = useGlobalStore((state) => state.monitoringThrottle);
  const { addMonitoringThrottle } = useGlobalStore((state) => state.actions);

  const navigate = useNavigate();
  const { monitoringId } = useParams();
  const { control, watch, handleSubmit, reset } = useForm<FormData>({
    defaultValues: { distance: 0 },
  });

  const { data: location, isLoading } = useQuery({
    queryKey: QueryKeys.locations.id(monitoringId as string),
    queryFn: () => MonitoringService.findOne({ monitoringId: monitoringId as string }),
    enabled: !!monitoringId,
  });

  const { mutate } = useMutation<Monitoring, ApiError, FormData>({
    mutationFn: (formData: FormData) => {
      if (location?.linkType === MonitoringLinkType.SMARTPHONE) {
        return MonitoringService.createMobile({ requestBody: { ...formData, locationId: location.locationId } });
      }

      return MonitoringService.create({ requestBody: { ...formData, locationId: location!.locationId } });
    },
    onSuccess: () => {
      if (location?.linkType === MonitoringLinkType.SMARTPHONE) {
        addMonitoringThrottle(monitoringId!);
      }

      reset();
      navigate(`/monitoring/${monitoringId}/daily`);
    },
    onError: (error) => {
      if (error.status === 429) {
        setThrottleError(true);
      }
    },
  });

  return isLoading ? null : (
    <>
      {location?.monitoringActive && !throttleError ? (
        <form
          name="monitoring-form"
          onSubmit={handleSubmit((submitData) => {
            if (
              location.linkType !== MonitoringLinkType.SMARTPHONE ||
              !monitoringThrottle[monitoringId!] ||
              dayjs(monitoringThrottle[monitoringId!]).isBefore(dayjs().subtract(1, 'hour'))
            ) {
              mutate(submitData);
            } else {
              setThrottleError(true);
            }
          })}
        >
          <CardTitle>
            <Typography variant="h2" component="h1">
              {location.monitoringTitle}
            </Typography>
            <Typography>{location?.name}</Typography>
          </CardTitle>

          <Stack sx={{ padding: { xs: 2, md: 4 } }} gap={4}>
            <RadioButtonsCommute
              name="commuteType"
              control={control}
              label="Welches Verkehrsmittel haben Sie genutzt?"
              commuteTypes={location.monitoringCommuteTypes as CommuteType[]}
            />

            <SliderDistance
              name="distance"
              control={control}
              label="Wie groß war die Entfernung?"
              subLabel="Bitte berücksichtigen Sie die einfache Wegstrecke"
              maxValue={location.monitoringScaleMax}
            />

            <Stack alignItems="center">
              <Button
                variant="contained"
                size="large"
                sx={{
                  paddingX: 8,
                }}
                type="submit"
                disabled={!watch('commuteType')}
              >
                Fertig
              </Button>
            </Stack>
          </Stack>
        </form>
      ) : (
        <>
          <CardTitle>
            <Typography variant="h2" component="h1">
              Limit erreicht
            </Typography>
          </CardTitle>
          <Box sx={{ padding: 3 }}>
            <Typography>
              Aufgrund einer zeitlichen Beschränkung ist derzeit keine weitere Teilnahme an der Umfrage möglich. Bitte
              probieren Sie es zu einem späteren Zeitpunkt noch einmal.
            </Typography>

            <Stack direction="row" gap={4} marginTop={4} justifyContent="space-between">
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setThrottleError(false);
                  reset();
                }}
              >
                Zurück
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  reset();
                  navigate(`/monitoring/${monitoringId}/daily`);
                }}
              >
                Übersicht
              </Button>
            </Stack>
          </Box>
        </>
      )}

      {location && !location.monitoringActive && (
        <>
          <CardTitle sx={{ display: 'flex' }}>
            <Typography variant="h2" component="h1">
              Monitoring nicht aktiv
            </Typography>
          </CardTitle>
          <Box sx={{ padding: 3 }}>
            <Typography>Das Monitoring ist für Ihren Standort derzeit nicht aktiviert</Typography>
          </Box>
        </>
      )}
    </>
  );
}
