import { Box, css, darken, IconButton, styled, Theme } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import logoBmm from '@/assets/icons/bmm-logo.svg?url';
import Info from '@/assets/icons/info.svg?react';
import logoIvm from '@/assets/icons/ivm-logo.png';
import logoSmm from '@/assets/icons/smm-logo.svg?url';
import backgroundImage from '@/assets/images/shutterstock.jpg';
import { MonitoringDialog } from '@/components/form-elements/MonitoringDialog';
import { theme as outerTheme } from '@/setup/theme';
import { transientOptions } from '@/utils/transientOptions';

const Root = styled(
  Box,
  transientOptions,
)<{ $backgroundImage: string }>(
  ({ theme, $backgroundImage }) => css`
    position: relative;
    min-height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url(${$backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(12)};

    ${theme.breakpoints.up('sm')} {
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
    }
  `,
);

const Wrapper = styled(Box)(
  ({ theme }) => css`
    max-width: ${theme.spacing(110)};
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: ${theme.spacing(2)};
  `,
);

const themeSmm = createTheme(outerTheme, {
  palette: {
    primary: {
      main: '#f8ff3d',
      dark: darken('#f8ff3d', 0.1),
      contrastText: '#3a3a3a',
    },
    secondary: {
      main: '#d1d3d4',
    },
  },
});
const themeIvm = createTheme(outerTheme, {
  palette: {
    primary: {
      main: '#cbe3f5',
      contrastText: '#3a3a3a',
      dark: darken('#cbe3f5', 0.1),
    },
    secondary: {
      main: '#F0F0F0',
    },
  },
});

export function MonitoringThemeProvider() {
  // TODO durch Wert aus Backend ersetzen
  const [innerTheme] = useState<'bmm' | 'smm' | 'ivm'>('bmm');
  const [open, setOpen] = useState(false);

  return (
    <ThemeProvider theme={{ smm: themeSmm, ivm: themeIvm, bmm: (theme: Theme) => theme }[innerTheme]}>
      <Root
        $backgroundImage={
          {
            bmm: backgroundImage,
            smm: backgroundImage,
            ivm: backgroundImage,
          }[innerTheme]
        }
      >
        <Box
          component="img"
          src={
            {
              bmm: logoBmm,
              smm: logoSmm,
              ivm: logoIvm,
            }[innerTheme]
          }
          alt={{ bmm: 'Besser zur Arbeit', smm: 'Besser zur Schule', ivm: 'ivm RheinMain' }[innerTheme]}
          sx={{ width: 'auto', height: 64, position: 'absolute', top: 16, left: 16 }}
        />
        <Wrapper>
          <Outlet />
        </Wrapper>

        <IconButton onClick={() => setOpen(true)} color="primary" sx={{ position: 'absolute', bottom: 16, left: 16 }}>
          <Info />
        </IconButton>

        <MonitoringDialog open={open} onClose={() => setOpen(false)} />
      </Root>
    </ThemeProvider>
  );
}
