import { Box, Grid } from '@mui/material';

type CommuteBadgeTotalProps = {
  count: number | undefined;
  size: number;
};

export function CommuteBadgeTotal({ count, size }: CommuteBadgeTotalProps) {
  return (
    <Grid item xs={size} key={0}>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <Box sx={{ paddingRight: 1 }}>{`Heute ${count === 1 ? 'hat' : 'haben'}`}</Box>
        <Box
          sx={{
            color: 'primary.main',
            fontSize: 40,
            fontWeight: 'bold',
            marginTop: '-5px',
          }}
        >
          {count !== 0 && count}
        </Box>
        <Box sx={{ paddingLeft: 1 }}>{`${count === 1 ? 'Person' : 'Personen'} an der Befragung teilgenommen`}</Box>
      </Box>
    </Grid>
  );
}
