import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import QRCode from 'react-qr-code';

import CopyIcon from '@/assets/icons/form/copy.svg?react';
import QRIcon from '@/assets/icons/form/qrcode.svg?react';
import { CheckboxesGroup } from '@/components/form-elements/CheckboxesGroup';
import { FormHeading } from '@/components/form-elements/FormHeading';
import { FormIndent } from '@/components/form-elements/FormIndent';
import { FormSection } from '@/components/form-elements/FormSection';
import { TextFieldLoading } from '@/components/form-elements/TextFieldLoading';
import { TextFieldText } from '@/components/form-elements/TextFieldText';
import { useIsFieldDisabled } from '@/hooks/useIsFieldDisabled';
import { CommuteType, Location, UpdateLocationDto } from '@/services/api';

export type FormDataLocationMonitoring = Pick<
  UpdateLocationDto,
  'monitoringTitle' | 'monitoringActive' | 'monitoringCommuteTypes' | 'monitoringScaleMax'
>;

type MonitoringConfigFormProps = {
  location?: Location;
  isLoading: boolean;
};

export function MonitoringConfigForm({ location, isLoading }: MonitoringConfigFormProps) {
  const isFieldDisabled = useIsFieldDisabled(location);

  const createLink = (id: string) => `${window.location.protocol}//${window.location.host}/monitoring/${id}`;

  const [tooltipLabel, setTooltipLabel] = useState('Link kopieren');
  const { watch } = useFormContext<FormDataLocationMonitoring>();

  const monitoringActive = watch('monitoringActive');

  const downloadQRCode = () => {
    const svg = document.getElementById('QRCode');
    if (!svg) return;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      if (!ctx) return;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <>
      <FormSection title="Monitoring Status">
        <Typography gutterBottom sx={{ marginLeft: 2 }}>{`Monitoring ist ${
          monitoringActive ? 'aktiviert' : 'deaktiviert'
        }`}</Typography>

        <Box sx={{ marginLeft: 2 }}>
          <TextFieldLoading isLoading={isLoading}>
            <Controller<FormDataLocationMonitoring, 'monitoringActive'>
              name="monitoringActive"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  disableTypography
                  label="aktiv"
                  value={field.name}
                  checked={field.value}
                  onChange={(event, checked) => field.onChange(checked)}
                  control={<Checkbox />}
                />
              )}
            />
          </TextFieldLoading>
        </Box>
      </FormSection>

      <FormSection title="Monitoring Überschrift">
        <TextFieldText
          label="Text"
          isLoading={isLoading}
          name="monitoringTitle"
          disabled={isFieldDisabled('monitoringTitle')}
        />
      </FormSection>

      <CheckboxesGroup<CommuteType, FormDataLocationMonitoring>
        label="Verfügbare Verkehrsmittel"
        name="monitoringCommuteTypes"
        i18nKey="CommuteType"
        options={Object.values(CommuteType)}
        isLoading={isLoading}
        gridXs={6}
      />

      <FormControl component="fieldset" sx={{ display: 'block', marginBottom: 2 }}>
        <FormHeading>Maximale Entfernung für Skala</FormHeading>
        <FormIndent>
          <Controller
            name="monitoringScaleMax"
            defaultValue={null}
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel key="100" value={100} control={<Radio />} label="100 km" />
                <FormControlLabel key="250" value={250} control={<Radio />} label="250 km" />
                <FormControlLabel key="500" value={500} control={<Radio />} label="500 km" />
                <FormControlLabel key="1000" value={1000} control={<Radio />} label="1000 km" />
              </RadioGroup>
            )}
          />
        </FormIndent>
      </FormControl>
      {location && (
        <>
          <FormSection title="Monitoring Links">
            {[
              {
                link: createLink(location.monitoringLinkIdTablet),
                label: 'Tablet',
              },
              {
                link: createLink(location.monitoringLinkIdIntranet),
                label: 'Intranet',
              },
              {
                link: createLink(location.monitoringLinkIdSmartphone),
                label: 'Smartphone',
              },
            ].map((value) => (
              <TextField
                key={value.label}
                label={value.label}
                disabled={!monitoringActive}
                value={value.link}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <Box sx={{ marginTop: '23px', cursor: 'pointer' }}>
                      <Tooltip
                        title={tooltipLabel}
                        onClose={() => tooltipLabel === 'Link kopiert' && setTooltipLabel('Link kopieren')}
                      >
                        <span>
                          <CopyIcon
                            onClick={async () => {
                              await navigator.clipboard.writeText(value.link);
                              setTooltipLabel('Link kopiert');
                            }}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  ),
                  endAdornment: value.label === 'Smartphone' && (
                    <Tooltip title="QR Code herunterladen">
                      <Box sx={{ marginTop: '8px', cursor: 'pointer' }}>
                        <QRIcon onClick={downloadQRCode} />
                      </Box>
                    </Tooltip>
                  ),
                }}
              />
            ))}
          </FormSection>
          <Box sx={{ display: 'none' }}>
            <QRCode id="QRCode" value={createLink(location.monitoringLinkIdSmartphone)} size={512} />
          </Box>
        </>
      )}
    </>
  );
}
