import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Input } from '@mui/material';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormHeading } from '@/components/form-elements/FormHeading';
import { FormIndent } from '@/components/form-elements/FormIndent';

type CheckboxesGroupProps<T extends string, U extends FieldValues> = {
  label: string;
  name: FieldPath<U>;
  otherName?: string;
  i18nKey: string;
  options: T[];
  isLoading: boolean;
  disabled?: boolean;
  gridXs?: number;
};

export function CheckboxesGroup<T extends string, U extends FieldValues>({
  label,
  name,
  otherName,
  i18nKey,
  options,
  isLoading,
  disabled,
  gridXs,
}: CheckboxesGroupProps<T, U>) {
  const { t } = useTranslation();
  const { register, watch } = useFormContext();
  const fieldValue = watch(name);

  return isLoading ? null : (
    <FormControl component="fieldset" sx={{ marginBottom: 2, display: 'flex', flexGrow: 1 }} disabled={disabled}>
      <FormHeading>{label}</FormHeading>
      <FormIndent>
        <FormGroup>
          <Controller<FieldValues>
            name={name}
            render={({ field }) => (
              <Grid container>
                {options.map((value) => (
                  <Grid key={value} item xs={gridXs || 12}>
                    <FormControlLabel
                      sx={{ marginRight: 0, ...(otherName && value === 'OTHER' && fieldValue && { flexGrow: 1 }) }}
                      disableTypography
                      label={
                        otherName && value === 'OTHER' && fieldValue && fieldValue.includes('OTHER') ? (
                          <Box
                            sx={{
                              flexGrow: 1,
                              display: 'inline-flex',
                              alignItems: 'center',
                              minWidth: '350px',
                            }}
                          >
                            {`${t(`${i18nKey}.${value}`)}: `}
                            <Box sx={{ display: 'inline-flex', flexGrow: 1 }}>
                              <Input
                                sx={{ marginLeft: 1, width: '100%' }}
                                disabled={disabled}
                                {...register(otherName)}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <>{t(`${i18nKey}.${value}`)}</>
                        )
                      }
                      value={value}
                      control={
                        <Checkbox
                          onChange={(event, checked) => {
                            const fielValue = field.value || [];
                            if (checked && !fielValue.includes(value)) {
                              field.onChange([...fielValue, value]);
                            }

                            if (!checked && fielValue.includes(value)) {
                              field.onChange(fielValue.filter((item: T) => item !== value));
                            }
                          }}
                          checked={((field.value as T[]) || []).includes(value)}
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </FormGroup>
      </FormIndent>
    </FormControl>
  );
}
