import { AnalyseMonitoringData } from '@/components/form-elements/MonitoringFilter';
import {
  AdminLevelsService,
  IndicatorProcessService,
  LocationsService,
  NetworksService,
  OrganizationsService,
  OrganizationUsersService,
  UsersService,
} from '@/services/api';

export class QueryKeys {
  static locations = {
    all: ['locations'],
    allFilters: (filters?: Parameters<typeof LocationsService.findAll>[0]) => [
      ...this.locations.all,
      'filters',
      ...(filters ? [filters] : []),
    ],
    id: (id: string) => [...this.locations.all, id],
  };

  static locationProfiles = {
    all: ['locationProfiles'],
    id: (id: string) => [...this.locationProfiles.all, id],
  };

  static adminLevels = {
    all: ['adminLevels'],
    allFilter: (parameters: Parameters<typeof AdminLevelsService.findAll>[0]) => [
      ...this.adminLevels.all,
      'filter',
      parameters,
    ],
    id: (id: string) => [...this.adminLevels.all, id],
    idIncludes: (
      id: string,
      { includeLocations = false }: Partial<Omit<Parameters<typeof AdminLevelsService.findOne>[0], 'id'>>,
    ) => [...this.adminLevels.id(id), { includeLocations }],
  };

  static networks = {
    all: ['networks'],
    id: (id: string) => [...this.networks.all, id],
    idIncludes: (
      id: string,
      { includeLocations = false }: Partial<Omit<Parameters<typeof NetworksService.findOne>[0], 'id'>>,
    ) => [...this.networks.id(id), { includeLocations }],
  };

  static organizations = {
    all: ['organizations'],
    allFilters: (filters?: Parameters<typeof OrganizationsService.findAll>[0]) => [
      ...this.organizations.all,
      'filters',
      ...(filters ? [filters] : []),
    ],
    id: (id: string) => [...this.organizations.all, id],
    idIncludes: (
      id: string,
      {
        includeLocations = false,
        includeOrganizationUsers = false,
      }: Partial<Omit<Parameters<typeof OrganizationsService.findOne>[0], 'id'>>,
    ) => [...this.organizations.id(id), { includeLocations, includeOrganizationUsers }],
  };

  static organizationUsers = {
    all: ['organizationUsers'],
    allFilters: (filters?: Parameters<typeof OrganizationUsersService.findAll>[0]) => [
      ...this.organizationUsers.all,
      'filters',
      ...(filters ? [filters] : []),
    ],
  };

  static users = {
    all: ['users'],
    allFilters: (filters?: Parameters<typeof UsersService.findAll>[0]) => [
      ...this.users.all,
      'filters',
      ...(filters ? [filters] : []),
    ],
    id: (id: string) => [...this.users.all, id],
  };

  static indicatorProcesses = {
    all: ['indicator-processes'],
    allFilters: (filters?: Parameters<typeof IndicatorProcessService.findAll>[0]) => [
      ...this.indicatorProcesses.all,
      'filters',
      ...(filters ? [filters] : []),
    ],
    id: (id: string) => [...this.indicatorProcesses.all, id],
  };

  static routingConfiguration = {
    getConfig: ['routingConfiguration', 'getConfig'],
  };

  static indicatorRelations = {
    getStatus: ['indicatorRelations', 'getStatus'],
  };

  static monitoring = {
    allFilters: (filters?: AnalyseMonitoringData) => ['monitoring', filters],
  };
}
