import { Box, Container, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AnalyseMonitoringData, MonitoringFilter } from '@/components/form-elements/MonitoringFilter';
import { FullHeightLayout } from '@/components/layouts/FullHeightLayout';
import { ActiveInactivePlot } from '@/monitoring/plots/ActiveInactivePlot';
import { CarbonDioxidBadge } from '@/monitoring/plots/CarbonDioxidBadge';
import { CarbonDioxidePlot } from '@/monitoring/plots/CarbonDioxidePlot';
import { DistancePlot } from '@/monitoring/plots/DistancePlot';
import { ModaSplitProgressPlot } from '@/monitoring/plots/ModaSplitProgressPlot';
import { ModalSplitPlot } from '@/monitoring/plots/ModalSplitPlot';
import { MovementAmount } from '@/monitoring/plots/MovementAmount';
import { ReducedCarbonDioxidePlot } from '@/monitoring/plots/ReducedCarbonDioxidePlot';
import { HandleDownload } from '@/services/DownloadHandler';
import { QueryKeys } from '@/services/QueryKeys';
import { LocationsService, MonitoringService, OrganizationsService } from '@/services/api';

const MONITORING_MINIMUM_DATA_SIZE = 20;

const filtersDefaultValues: AnalyseMonitoringData = {
  locationId: null as unknown as undefined,
  organizationId: null as unknown as undefined,
  networkId: null as unknown as undefined,
  adminLevelId: null as unknown as undefined,
  start: dayjs().startOf('year'),
  end: dayjs().endOf('year'),
  includeWayBack: true,
};

export function MonitoringAnalysisPage() {
  const { organizationId } = useParams();
  filtersDefaultValues.organizationId = organizationId !== undefined ? organizationId : (null as unknown as undefined);
  const [filters, setFilters] = useState<AnalyseMonitoringData>(filtersDefaultValues);
  const [showIcons, setShowIcons] = useState(true);
  const [showSubtitle, setShowSubtitle] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [extrapolation, setExtrapolation] = useState(true);

  const { t } = useTranslation();

  const methods = useForm({ defaultValues: filtersDefaultValues });

  const { data: organizations = [] } = useQuery({
    queryKey: QueryKeys.organizations.allFilters({ includeLocations: false }),
    queryFn: () => OrganizationsService.findAll({ includeLocations: false }),
    refetchOnWindowFocus: false,
  });

  const { data: locations = [] } = useQuery({
    queryKey: QueryKeys.locations.all,
    queryFn: () => LocationsService.findAll(),
    refetchOnWindowFocus: false,
  });

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.monitoring.allFilters(filters),
    queryFn: () =>
      MonitoringService.analysis({
        requestBody: {
          locationId: filters.locationId,
          organizationId: filters.organizationId,
          adminLevelId: filters.adminLevelId,
          networkId: filters.networkId,
          start: filters.start.toISOString(),
          end: filters.end.toISOString(),
          includeWayBack: filters.includeWayBack,
        },
      }),
    refetchOnWindowFocus: false,
    ...(autoRefresh && { refetchInterval: 30000 }),
  });

  const monitoringDataSize = data?.map((commuteTypeData) => commuteTypeData.data).length;

  return (
    <FullHeightLayout>
      <Container>
        <FormProvider {...methods}>
          <form>
            <MonitoringFilter
              onDownload={() => {
                if (!data) return;

                const header = ['Verkehrsmittel', 'Datum', 'Zeit', 'Distanz', 'Standort', 'Arbeitgeber'];
                const rowsData = data
                  .map((commuteTypeData) => commuteTypeData.data)
                  .flat()
                  .map((original) => original);

                const csvData = rowsData.map((monData) => {
                  const location = locations.find((locationItem) => locationItem.id === monData.locationId);
                  const organization = organizations.find(
                    (organizationItem) => organizationItem.id === location?.organizationId,
                  );

                  return [
                    t(`CommuteType.${monData.commuteType}`),
                    dayjs(monData.createdAt).format('L'),
                    dayjs(monData.createdAt).format('LT'),
                    monData.distance,
                    location?.name,
                    organization?.name,
                  ];
                });
                HandleDownload({ csvData, header, name: 'BESSER Monitor Rohdaten' });
              }}
              defaultValues={filtersDefaultValues}
              onFilter={(formData) => {
                setFilters(formData);
              }}
              showIcons={showIcons}
              setShowIcons={setShowIcons}
              showSubtitle={showSubtitle}
              setShowSubtitle={setShowSubtitle}
              autoRefresh={autoRefresh}
              setAutoRefresh={setAutoRefresh}
              monitoringDataLength={data?.reduce(
                (prev, curr) => prev + (filters.includeWayBack ? curr.count / 2 : curr.count),
                0,
              )}
              extrapolation={extrapolation}
              setExtrapolation={setExtrapolation}
            />
          </form>
        </FormProvider>
        {!isLoading &&
          ((monitoringDataSize && monitoringDataSize > 0 && organizationId === undefined) ||
            (monitoringDataSize &&
              monitoringDataSize >= MONITORING_MINIMUM_DATA_SIZE &&
              organizationId !== undefined)) && (
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              <Grid item xs={6}>
                <CarbonDioxidBadge extrapolation={extrapolation} data={data} reduced={false} />
              </Grid>
              <Grid item xs={6}>
                <CarbonDioxidBadge extrapolation={extrapolation} data={data} reduced />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <ModalSplitPlot data={data} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <ActiveInactivePlot data={data} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <DistancePlot
                    data={data}
                    showIcons={showIcons}
                    includeWayBack={filters.includeWayBack}
                    showSubtitle={showSubtitle}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <MovementAmount data={data} showIcons={showIcons} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <CarbonDioxidePlot data={data} showIcons={showIcons} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <ReducedCarbonDioxidePlot data={data} showIcons={showIcons} showSubtitle={showSubtitle} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: '#ededed' }}>
                  <ModaSplitProgressPlot
                    data={data.map((commuteTypeData) => commuteTypeData.data).flat()}
                    showSubtitle={showSubtitle}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        {!isLoading &&
          monitoringDataSize &&
          monitoringDataSize < MONITORING_MINIMUM_DATA_SIZE &&
          organizationId !== undefined && (
            <Typography sx={{ marginTop: 2 }}>
              Die verfügbare Datengrundlage unterschreitet die erforderliche Mindest-Stichprobengröße für eine
              Diagramm-Anzeige. Bitte passen Sie die Filterkriterien an oder informieren Sie sich zu einem späteren
              Zeitpunkt des Erhebungszeitraums über die Ergebnisse.
            </Typography>
          )}
      </Container>
    </FullHeightLayout>
  );
}
