import { createTheme, css, responsiveFontSizes, ThemeOptions } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { deDE } from '@mui/material/locale';

import DomusBoldWoff from '@/assets/fonts/jaf-domus/Domus-Web-Bold.woff';
import DomusBoldWoff2 from '@/assets/fonts/jaf-domus/Domus-Web-Bold.woff2';
import DomusRegularWoff from '@/assets/fonts/jaf-domus/Domus-Web-Regular.woff';
import DomusRegularWoff2 from '@/assets/fonts/jaf-domus/Domus-Web-Regular.woff2';
import Calendar from '@/assets/icons/form/calendar.svg?react';
import Dropdown from '@/assets/icons/form/dropdown.svg?react';
import Complete from '@/assets/icons/status/complete.svg?react';

declare module '@mui/material' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme(
  {
    palette: {
      primary: {
        main: '#48c385',
        contrastText: '#ffffff',
        light: '#ecf9f3',
      },
      secondary: {
        main: '#ededed',
        contrastText: '#3a3a3a',
        light: '#fafafa',
      },

      tertiary: {
        main: '#86888c',
        contrastText: '#ffffff',
        light: '#ededed',
      },

      success: {
        main: '#48c385',
        contrastText: '#ffffff',
      },

      text: {
        primary: '#3a3a3a',
        secondary: '#646464',
        disabled: '#86888c',
      },
      divider: '#ededed',
      action: {
        hover: '#fafafa',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: ['jaf-domus', 'sans-serif'].join(','),

      allVariants: {
        fontSize: '1rem',
      },

      h1: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
      },
    },
    shape: {
      borderRadius: 0,
    },
    shadows: [
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
  },
  deDE,
);

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: css`
          font-weight: bold;
        `,
        sizeLarge: css`
          padding: ${theme.spacing(2, 3)};
        `,
        text: css`
          padding-left: ${theme.spacing(2)};
          padding-right: ${theme.spacing(2)};
          text-transform: none;
        `,
      },
    },
    MuiCssBaseline: {
      styleOverrides: () => css`
        @font-face {
          font-family: 'jaf-domus';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: ${[DomusRegularWoff, DomusRegularWoff2]
            .map((font, index) => `url(${font}) format('woff${index > 0 ? '2' : ''}')`)
            .join(', ')};
        }
        @font-face {
          font-family: 'jaf-domus';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: ${[DomusBoldWoff, DomusBoldWoff2]
            .map((font, index) => `url(${font}) format('woff${index > 0 ? '2' : ''}')`)
            .join(', ')};
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: css`
          &.MuiTypography-h1 {
            margin-bottom: ${theme.spacing(5)};
          }
        `,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: css`
          border-bottom-width: 2px;
        `,
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginTopCenter: css`
          top: ${theme.spacing(10)} !important;
        `,
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: { success: <Complete /> },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: css`
          min-height: ${theme.spacing(7)} !important;
        `,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: css`
          text-transform: none;
        `,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        fullWidth: true,
        SelectProps: {
          native: true,
          IconComponent: Dropdown,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: { popupIcon: <Dropdown /> },
      styleOverrides: {
        root: css`
          .MuiFilledInput-root .MuiAutocomplete-endAdornment {
            right: ${theme.spacing(3)};
          }
        `,
        paper: css`
          border: ${theme.spacing(0.25)} solid ${theme.palette.divider};
          border-top: none;
        `,
        popupIndicator: css`
          color: ${theme.palette.text.primary};
          width: ${theme.spacing(4)};
          height: ${theme.spacing(4)};
        `,
      },
    },
    MuiNativeSelect: {
      defaultProps: {
        IconComponent: Dropdown,
      },
      styleOverrides: {
        select: css`
          &.MuiNativeSelect-filled.MuiFilledInput-input.MuiInputBase-input {
            padding-right: ${theme.spacing(5)};
          }
        `,
        icon: css`
          color: ${theme.palette.text.primary};
          top: 50%;
          transform: translateY(-50%);

          .MuiNativeSelect-filled ~ & {
            right: ${theme.spacing(3)};
          }

          .MuiNativeSelect-outlined ~ & {
            right: ${theme.spacing(1)};
          }
        `,
      },
    },
    MuiDatePicker: {
      defaultProps: {
        InputAdornmentProps: {
          sx: css`
            .MuiIconButton-edgeEnd {
              margin-right: 0;
            }
          `,
        },
        components: {
          OpenPickerIcon: Calendar,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: css`
          left: ${theme.spacing(0.5)};
          max-width: calc(100% - ${theme.spacing(4)});
        `,
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: css`
          background-color: ${theme.palette.secondary.light};
        `,
        underline: css`
          &::before,
          &::after {
            content: none;
          }
        `,
        input: css`
          padding-left: ${theme.spacing(2)};
          padding-right: ${theme.spacing(2)};
        `,
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: css`
          background-color: ${theme.palette.secondary.light};
        `,
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: css`
          border: 2px solid ${theme.palette.divider};
        `,
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: css`
          .MuiTableBody-root &:nth-of-type(odd) {
            background-color: ${theme.palette.action.hover};
          }

          .MuiTableBody-root &:last-child td,
          .MuiTableBody-root &:last-child th {
            border: 0;
          }
        `,
        head: css`
          th {
            border-bottom: 2px solid ${theme.palette.divider};
            color: ${theme.palette.text.disabled};
          }
        `,
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
} as ThemeOptions);

theme = responsiveFontSizes(theme);

export { theme };
