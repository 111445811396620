import { useParams } from 'react-router-dom';

import { SecondaryNavigation } from '@/components/navigations/SecondaryNavigation';
import { User, UserRole } from '@/services/api';
import { useGlobalStore } from '@/stores/useGlobalStore';

export function LocationNavigation() {
  const { organizationId, locationId } = useParams();
  const user = useGlobalStore((state) => state.user);

  return (
    <SecondaryNavigation
      items={
        locationId && locationId !== 'erstellen'
          ? [
              {
                label: 'Standort-Information',
                to: `/arbeitgeber/${organizationId}/standorte/${locationId}`,
                end: true,
              },
              { label: 'Standort-Steckbrief', to: `/arbeitgeber/${organizationId}/standorte/${locationId}/steckbrief` },
              ...([UserRole.TRAFFICON_ADMIN, UserRole.IVM_ADMIN].includes((user as User).role)
                ? [
                    {
                      label: 'BESSER Monitor',
                      to: `/arbeitgeber/${organizationId}/standorte/${locationId}/monitoring-einstellung`,
                    },
                  ]
                : []),
            ]
          : []
      }
    />
  );
}
