import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/monitoring/plots/Plot';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { HandleDownload } from '@/services/DownloadHandler';
import { CommuteType, MinimumMonitoringDto } from '@/services/api';

type ModaSplitProgressPlotProps = {
  data: MinimumMonitoringDto[];
  showSubtitle: boolean;
  from?: Date;
  to?: Date;
};

function groupByDate(list: MinimumMonitoringDto[]) {
  const result: Array<{ date: string; entries: MinimumMonitoringDto[] }> = [];
  list.forEach((obj) => {
    if (result.find((entry) => entry.date === obj.createdAt)) {
      result.find((entry) => entry.date === obj.createdAt)?.entries.push(obj);
    } else {
      result.push({ date: obj.createdAt, entries: [obj] });
    }
  });

  return result;
}

export function ModaSplitProgressPlot({ data, showSubtitle, from, to }: ModaSplitProgressPlotProps) {
  const { t } = useTranslation();
  const filterdData = data
    .filter((obj) => (from && to ? new Date(obj.createdAt) >= from && new Date(obj.createdAt) <= to : true))
    .map((obj) => ({ ...obj, createdAt: obj.createdAt.split('T')[0] }));

  const plotData = groupByDate(filterdData);
  const filterParams = Object.values(CommuteType).map((obj) => ({
    name: t(`CommuteType.${obj}`) as string,
    x: plotData.map((day) => day.date),
    y: plotData.map((day) => day.entries.filter((rec) => rec.commuteType === obj).length),
    type: 'bar' as 'bar',
    marker: { color: CommuteUtils[obj].color },
  }));

  const header = ['Datum', 'Verkehrsmittel', 'Fahrten'];
  const csvData = filterParams.flatMap((param) =>
    param.x.map((date, index) => ({
      date: dayjs(date).format('DD.MM.YYYY'),
      name: param.name,
      count: param.y[index],
    })),
  );

  csvData.sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf());

  return (
    <>
      <Button onClick={() => HandleDownload({ csvData, header, name: 'Zeitverlauf' })}>Download CSV</Button>
      <Plot
        data={filterParams}
        layout={{
          barmode: 'stack',
          title: `Entwicklung der Verkehrsmittelanteile${
            showSubtitle ? '<br><sub>Anzahl der Fahrten pro Verkehrsmittel im zeitlichen Verlauf</sub>' : ''
          }`,
          yaxis: { fixedrange: true },
          xaxis: {
            tickformat: '%_d\n%B',
            tickformatstops: plotData.map((day) => ({ templateitemname: '%_d\n%B', value: day.date })),
            tickmode: plotData.length > 5 ? 'auto' : 'array',
            tickvals: plotData.map((day) => day.date),
          },
        }}
        config={{
          modeBarButtonsToRemove: ['lasso2d', 'zoom2d', 'pan2d', 'select2d', 'resetScale2d'],
        }}
        style={{ width: '100%', height: '100%' }}
      />
    </>
  );
}
