import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { LastSaved } from '@/components/form-elements/LastSaved';
import { SidebarLayout } from '@/components/layouts/SidebarLayout';
import { useAppAbility } from '@/hooks/useAppAbility';
import { useAutoSave } from '@/hooks/useAutoSave';
import { useInitialFormValues } from '@/hooks/useInitialFormValues';
import { MonitoringConfigForm } from '@/monitoring/configuration/MonitoringConfigForm';
import { DataTransformer } from '@/services/DataTransformer';
import { QueryKeys } from '@/services/QueryKeys';
import { Action, LocationsService, UpdateLocationDto, Location } from '@/services/api';

const navigationItems = ['Einstellungen'];

type FormData = Pick<
  UpdateLocationDto,
  'monitoringTitle' | 'monitoringActive' | 'monitoringCommuteTypes' | 'monitoringScaleMax'
>;

export function MonitoringConfigurationPage() {
  const { locationId } = useParams();
  const ability = useAppAbility();

  const queryClient = useQueryClient();
  const { data: location } = useQuery({
    queryKey: QueryKeys.locations.id(locationId as string),
    queryFn: () => LocationsService.findOne({ id: locationId as string }),
    enabled: !!locationId,
  });

  const methods = useForm<FormData>();
  const [lastSaved, setLastSaved] = useState<Dayjs>();

  useInitialFormValues<FormData>({
    entity: location,
    useFormReturn: methods,
    fields: ['monitoringTitle', 'monitoringActive', 'monitoringCommuteTypes', 'monitoringScaleMax'],
  });

  const { mutate } = useMutation({
    mutationFn: (formData: UpdateLocationDto) =>
      LocationsService.update({ id: locationId as string, requestBody: formData }),
    onMutate: () => setLastSaved(undefined),
    onSuccess: async () => {
      setLastSaved(dayjs());
      await queryClient.invalidateQueries({ queryKey: QueryKeys.locations.id(locationId as string) });
    },
  });

  const handleSubmit = methods.handleSubmit(
    (formData) => {
      const transformedFormData: UpdateLocationDto = DataTransformer.toApi(
        {
          ...formData,
        },
        (field) => ability.can(Action.UPDATE, location as Location, field),
      );

      mutate(transformedFormData);
    },
    () => setLastSaved(undefined),
  );
  useAutoSave(handleSubmit, methods.watch);

  const disabled = !location || ability.cannot(Action.UPDATE, location);

  return (
    <SidebarLayout
      type="tabs"
      navigationItems={navigationItems}
      buttonLabel={disabled ? undefined : 'Speichern'}
      actionArea={<LastSaved lastSaved={lastSaved} />}
    >
      {(tab: number) =>
        tab === 0 && (
          <FormProvider {...methods}>
            <form id="main-form" onSubmit={handleSubmit}>
              <MonitoringConfigForm location={location} isLoading={!location} />
            </form>
          </FormProvider>
        )
      }
    </SidebarLayout>
  );
}
