import { Box, css, IconButton, Stack, styled, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

import Arrows from '@/assets/icons/commute/arrows.svg?react';
import CarIcon from '@/assets/icons/commute/car.svg?react';
import Co2Icon from '@/assets/icons/commute/co2.svg?react';
import PtIcon from '@/assets/icons/commute/pt.svg?react';
import Info from '@/assets/icons/info-icon-plotly.svg?react';
import { CommuteType, MonitoringAnalysisData } from '@/services/api';

const StyledInfo = styled(Info)(
  () => css`
    width: 16px;
    height: auto;
  `,
);

const StyledBox = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.secondary.main};
    position: relative;
  `,
);

const StyledContent = styled(Box)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.spacing(1.5)} 0;
  `,
);

const infoTextStyle: SxProps = {
  textAlign: 'center',
  fontSize: 20,
};

type CarbonDioxidBadgeProps = {
  data: MonitoringAnalysisData[];
  reduced: boolean;
  extrapolation: boolean;
};

export function CarbonDioxidBadge({ data, reduced, extrapolation }: CarbonDioxidBadgeProps) {
  const days = [
    ...new Set(
      data
        .map((commuteTypeData) => commuteTypeData.data)
        .flat()
        .map((obj) => obj.createdAt.split('T')[0]),
    ),
  ].length;
  const theme = useTheme();

  const totalCo2 =
    data
      .filter((obj) => obj.commuteType === CommuteType.CAR_MOTORCYCLE || obj.commuteType === CommuteType.CAR_ELECTRIC)
      .reduce((total, current) => total + (reduced ? current.reducedCarbonDioxide : current.carbonDioxide), 0) / 1000;

  const isKg = totalCo2 < 1;
  const co2Amount = Number(totalCo2 < 1 ? totalCo2 : totalCo2);
  const eventAmount = (isKg ? co2Amount * 1000 : co2Amount).toFixed(2);
  const annualAmount = Number(((totalCo2 * 207) / days).toFixed(2));

  const [style, setStyle] = useState({ display: 'none' });

  let tooltipText;
  if (reduced) {
    tooltipText =
      'Reduzierung bei Umstieg auf ÖPNV und aktive Mobilität.\nDistanz < 10 km: Umstieg auf Fuß, Fahrrad, Pedelec.\nDistanz >= 10 km: Umstieg auf ÖPNV';
  } else if (!extrapolation) {
    tooltipText = 'MIV-Gesamtmenge CO2 laut Dashboard-Filter.\n CO2-Preis = 80 € pro Tonne.';
  } else {
    tooltipText =
      'MIV-Gesamtmenge CO2 laut Dashboard-Filter\ngeteilt durch die Tage mit Umfrageergebnissen.\nCO2-Preis = 80€ pro Tonne. Jahr = 207 Arbeitstage';
  }

  let resultText;
  if (reduced) {
    resultText = 'Wechsel auf Umweltverbund';
  } else if (!extrapolation) {
    resultText = 'Summe';
  } else {
    resultText = 'Hochrechnung';
  }

  return (
    <StyledBox onMouseEnter={() => setStyle({ display: 'block' })} onMouseLeave={() => setStyle({ display: 'none' })}>
      <Tooltip
        slotProps={{
          tooltip: {
            sx: { backgroundColor: 'rgb(105, 115, 138)', borderRadius: '2px', whiteSpace: 'pre', marginTop: 0 },
          },
          arrow: { sx: { color: 'rgb(105, 115, 138)' } },
        }}
        title={tooltipText}
        arrow
      >
        <IconButton size="small" sx={{ position: 'absolute', top: 0, right: 0 }} style={style}>
          <StyledInfo />
        </IconButton>
      </Tooltip>
      <StyledContent>
        <Typography sx={{ textAlign: 'center', marginTop: 1 }}>{resultText}</Typography>
        <Stack direction="row" spacing={3} justifyContent="center" alignItems="center">
          <Co2Icon width={50} height={50} color={theme.palette.primary.main} />
          <CarIcon width={50} height={50} color={theme.palette.primary.main} />
          {reduced && <Arrows style={{ height: 'auto', width: 30 }} color={theme.palette.primary.main} />}
          {reduced && <PtIcon style={{ width: 50, height: 'auto' }} color={theme.palette.primary.main} />}
          <Typography sx={infoTextStyle}>
            <b>{!extrapolation ? eventAmount : annualAmount.toLocaleString('de')}</b>{' '}
            {!extrapolation && isKg ? 'kg' : 't'} CO2
          </Typography>
          <Typography sx={infoTextStyle} gutterBottom>
            <b>
              {(!extrapolation ? eventAmount : annualAmount * 80).toLocaleString('de-DE', {
                style: 'decimal',
                currency: 'EUR',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </b>{' '}
            €{extrapolation && ' p.a.'}
          </Typography>
        </Stack>
      </StyledContent>
    </StyledBox>
  );
}
