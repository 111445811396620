import { Button, useTheme } from '@mui/material';
import i18n from 'i18next';

import { Plot } from '@/monitoring/plots/Plot';
import { CommuteUtils } from '@/monitoring/utils/CommuteUtils';
import { HandleDownload } from '@/services/DownloadHandler';
import { MonitoringAnalysisData } from '@/services/api';

type ModalSplitProps = {
  data: MonitoringAnalysisData[];
  showSubtitle: boolean;
};

export function ModalSplitPlot({ data, showSubtitle }: ModalSplitProps) {
  const theme = useTheme();

  const plotData = data.filter((obj) => obj.count > 0);
  const filterdData = [
    {
      values: plotData.map((obj) => obj.count),
      labels: plotData.map((obj) => i18n.t(`CommuteTypeShort.${obj.commuteType}`)),
      type: 'pie',
      hole: 0.7,
      sort: false,
      texttemplate: '%{percent:.0%}',
      marker: {
        colors: plotData.map((obj) => CommuteUtils[obj.commuteType].color),
        line: {
          color: theme.palette.tertiary.light,
          width: 1,
        },
      },
      hoverinfo: 'label+value+percent',
    },
  ];

  const header = ['Verkehrsmittel', 'Anteil', 'in Prozent'];
  const csvData = filterdData.flatMap((param) => {
    const total = param.values.reduce((acc, currentValue) => acc + currentValue, 0);

    return param.values.map((value, index) => ({
      label: param.labels[index],
      values: value,
      percent: Number(((value / total) * 100).toFixed(1)).toLocaleString('de-DE'),
    }));
  });

  return (
    <>
      <Button onClick={() => HandleDownload({ csvData, header, name: 'Verkehrsmittelnutzung' })}>Download CSV</Button>
      <Plot
        data={filterdData as any}
        layout={{
          title: `Verkehrsmittelnutzung${
            showSubtitle ? '<br><sub>Verkehrsmittelanteile (Anzahl zurückgelegte Wege)</sub>' : ''
          }`,
          showlegend: true,
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </>
  );
}
